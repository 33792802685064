import { isPast } from 'date-fns'
import { PageProps, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { useEffect, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { SEO } from '../../../SEO'
import { Button } from '../../../components/Button'
import CalendarEvents from '../../../components/CalendarEvents'
import Container from '../../../components/Container'
import Footer from '../../../components/Footer/Footer'
import Intro from '../../../components/Intro'
import ShareLinks from '../../../components/ShareLinks'
import { Site } from '../../../components/Site'
import { Main } from '../../../components/Styled'
import { b64_to_utf8 } from '../../../utils/b64_to_utf8'

const DrClaireAshtonJames: React.FC<PageProps> = props => {
  const [{ url }, setEventDetails] = useState({
    url: 'aHR0cHM6Ly91czA2d2ViLnpvb20udXMvd2ViaW5hci9yZWdpc3Rlci9XTl84R2xWU1pDRVNfeVZHU2FBOGw5WHpR'
  })

  const theme = useTheme()
  const ref = useRef<HTMLDivElement>(null)
  const { claire, one, two, three, og } = useStaticQuery(graphql`
    query Claire {
      og: file(relativePath: { eq: "og-event-1.jpg" }) {
        publicURL
      }
      claire: file(relativePath: { eq: "dr-claire-event-graphic.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      one: file(relativePath: { eq: "roundtable-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      two: file(relativePath: { eq: "roundtable-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      three: file(relativePath: { eq: "roundtable-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const isPastEvent = isPast(new Date('2021-11-30 10:00:00'))
  const handleScrollTo = () => ref.current && ref.current.scrollIntoView({ behavior: 'smooth' })
  const description = 'Leveraging AI to optimise claimant outcomes, with social psychologist, Dr Claire Ashton-James'
  const meetingInfo = `daisee is inviting you to the Insurance Roundtable Webinar.`

  const imgHeight = '25em'
  const imgStyle = {
    objectFit: 'cover',
    height: imgHeight,
    borderRadius: '1.5rem',
    boxShadow: '0 5px 12px rgba(0,0,0,0.12), 0 3px 3px rgba(0,0,0,0.08)'
  }

  useEffect(() => {
    setEventDetails(prevState => ({
      url: b64_to_utf8(prevState.url)
    }))
  }, [])

  return (
    <Site seo={{ ...SEO.drClaire, image: og.publicURL }} {...props}>
      <Main>
        <Intro bgColor='purple100'>
          <Container>
            <PageContent>
              <div className='grid' ref={ref}>
                <div className='copy'>
                  <h1 className='heading'>Insurance Roundtable</h1>
                  <h2>{description}</h2>
                  <div className='details'>
                    <div className='row'>
                      <div className='bold'>Date:</div>
                      <div>November 30, 2021</div>
                    </div>
                    <div className='row'>
                      <div className='bold'>Time:</div>
                      <div>9:00-10:00 AM AEST</div>
                    </div>
                  </div>
                  {isPastEvent ? (
                    <div className='isPast'>
                      {' '}
                      <div className='over'>This event has finished</div>{' '}
                      <div>
                        Stay up to date with Dr. Ashton-James by visiting her{' '}
                        <a className='link' target='_blank' href='https://drashtonjames.com/'>
                          website
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className='copy'>
                      <a className='register-link' href='https://us06web.zoom.us/webinar/register/WN_8GlVSZCES_yVGSaA8l9XzQ'>
                        <Button bgColor={theme.colors.purple} btnHeight='auto' action='Register Here'/>
                      </a>
                      <ShareLinks text='Insurance Roundtable' />
                      <CalendarEvents
                        title='daisee - Insurance Roundtable'
                        description={description + '\n\n' + meetingInfo}
                        location={url}
                        startDateTime='2021-11-30 9:00:00'
                        endDateTime='2021-11-30 10:00:00'
                      />
                    </div>
                  )}
                </div>
                <Img imgStyle={{ objectFit: 'contain' }} fluid={claire.childImageSharp.fluid} />
              </div>
            </PageContent>
          </Container>
        </Intro>
        <Container>
          <PageContent>
            <div className='inner'>
              <h3 className='heading'>Claimant distress is bad for everyone - especially the Case Manager</h3>
              <p>
                Insurance claims case managers deal with people on some of the most stressful days of their lives, in
                situations that can quickly lead to frustration. Your case managers are in the firing line and this
                Insurance Roundtable lays out how to identify and manage the different forms of claimant distress.
                Understand how you can take action to ensure positive outcomes for your customers, employees, and
                business.
              </p>
            </div>
          </PageContent>
        </Container>
        <QuoteWrapper>
          <Container>
            <div className='grid'>
              <Img style={{ height: imgHeight }} imgStyle={imgStyle} fluid={one.childImageSharp.fluid} />
              <div className='copy'>
                <div className='hang-line' />
                <sup>“</sup>
                <h3 className='quote'>
                  A universal challenge faced by compensation systems is that the recovery trajectories of injured
                  persons who make a claim are generally worse than those of injured persons who do not make a claim
                </h3>
                <sub>”</sub>
              </div>
            </div>
          </Container>
        </QuoteWrapper>
        <Container>
          <PageContent>
            <div className='inner'>
              <h3>Did you know?</h3>
              <ul>
                <li>Complaints are higher for claims than any other part of the insurance experience.</li>
                <li>Distress caused by the claims process can affect a claimant’s physical recovery.</li>
                <li>Complaints can cause a transfer of distress to the case manager, leading to staff turnover.</li>
              </ul>
              <p>
                Healthcare delivery expert and lead speaker, Dr. Claire Ashton-James, will speak directly to her latest
                findings on the direct link between the claims process and client and business outcomes. She will
                discuss strategies that can be used to improve claimant engagement and return to work as well as case
                manager coaching and retention. She will draw on her specific expertise in developing early
                interventions to manage claimant distress following accidents, and her knowledge of the psychosocial
                approach to pain management.
              </p>
            </div>
          </PageContent>
        </Container>
        <QuoteWrapper bg>
          <Container>
            <div className='grid reverse'>
              <div className='copy'>
                <div className='hang-line' />
                <sup>“</sup>
                <h3 className='quote'>
                  the claimants’ experience of the claims process is a critical predictor of their likelihood of
                  recovery and return to work
                </h3>
                <sub>”</sub>
              </div>
              <Img style={{ height: imgHeight }} imgStyle={imgStyle} fluid={three.childImageSharp.fluid} />
            </div>
          </Container>
        </QuoteWrapper>
        <Container>
          <PageContent>
            <div className='inner'>
              <h3 className='heading'>
                Cover 100% of calls and enhance the value of customer conversations with daisee
              </h3>
              <p>
                Optimising claimant experiences with customer-centric service is key to handling claims, reducing
                complaints and retaining insurance customers. daisee specialises in conversational analytics technology
                and leverages Artificial Intelligence to actively recognise and analyse meaningful phrases that could
                lead to a claim or complaint. daisee helps you hear and detect the true reason behind your customers’
                voice interactions, including distress, to help you optimise outcomes for both you and your customers.
              </p>
            </div>
          </PageContent>
        </Container>
        <CTA>
          <Container>
            <PageContent>
              <div className='inner'>
                <div className='cta-text'>
                  Register for our Insurance Roundtable today and join us on Tuesday, November 30th and receive your
                  free White Paper on Claimant Distress.
                </div>
                <Button bgColor={theme.colors.green} btnHeight='auto' action='register' onClick={handleScrollTo} />
              </div>
            </PageContent>
          </Container>
        </CTA>
        <QuoteWrapper>
          <Container>
            <div className='grid'>
              <Img style={{ height: imgHeight }} imgStyle={imgStyle} fluid={two.childImageSharp.fluid} />
              <div className='copy'>
                <div className='hang-line' />
                <sup>“</sup>
                <h3 className='quote'>
                  communication with case managers plays a critical role in claimant’s experience and perceptions of
                  fairness
                </h3>
                <sub>”</sub>
              </div>
            </div>
          </Container>
        </QuoteWrapper>
        <Container>
          <PageContent>
            <div className='inner last'>
              <h3 className='heading'>About Dr. Claire Ashton-James</h3>
              <p>
                Claire Ashton-James, PhD is a social psychologist and a faculty member at The University of Sydney. Her
                work touches on the role of social support and social wellbeing in treatment outcomes, interpersonal
                modulation of pain, the impact of trust on pain, health behaviour and well-being, and communication
                skills. Dr. Ashton-James conducted her PhD research at UNSW (Australia) and Duke University (USA). She
                was a postdoctoral fellow at the University of British Columbia (Canada) before moving to The
                Netherlands to work as an Assistant Professor at the University of Groningen, and later, the Free
                University of Amsterdam (Department of Psychology and the VU Medical Center Amsterdam).{' '}
              </p>
              <a className='link' target='_blank' href='https://drashtonjames.com/'>
                https://drashtonjames.com/
              </a>
            </div>
          </PageContent>
        </Container>
      </Main>
      <Footer />
    </Site>
  )
}

const QuoteWrapper = styled.div<{ bg?: boolean }>`
  background: ${({ theme, bg }) => (bg ? theme.colors.purple : '#fff')};
  margin: 3rem 0;
  position: relative;
  padding: 1.5rem 0;

  .hang-line {
    width: 96px;
    height: 4px;
    background: ${({ theme, bg }) => (bg ? '#fff' : theme.colors.purple)};
    position: relative;
    top: -1.5rem;
  }

  h3 {
    margin: 0;
    font-weight: ${({ bg }) => (bg ? 400 : 500)};
    font-size: 1.75rem;
    letter-spacing: -1px;
    line-height: 1.4;
    color: ${({ theme, bg }) => (bg ? '#fff' : theme.colors.purple)};
  }

  sup,
  sub {
    color: ${({ theme, bg }) => (bg ? '#fff' : theme.colors.purple)};
    font-size: 3rem;
    opacity: 0.5;
  }

  sub {
    bottom: -0.75em;
    font-family: times;
  }

  sup {
    top: 0.25em;
    font-family: times;
  }

  .grid {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    gap: 1.5rem;
    align-items: center;
  }

  .reverse {
    grid-template-columns: 1fr 0.5fr;
  }


  ${({ theme }) => theme.breakpoints.mobile} {
    .copy {
      padding-top: 1.5rem;
    }

    .grid {
      grid-template-columns: 1fr;
    }

    .reverse {
      grid-template-columns: 1fr;
      padding: 1.5rem 0;
    }
  }
`

const CTA = styled.div`
  background: ${({ theme }) => theme.colors.grey200};
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  padding: 6rem 0;
  margin: 3rem 0;

  .cta-text {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
    color: ${({ theme }) => theme.colors.purple};
  }

  button {
    margin: 0 auto;

    * {
      margin: 0 !important;
    }
  }
`

const PageContent = styled.div`
  .heading {
    margin: 0;
    font-weight: 500;
    font-size: 2.25rem;
    letter-spacing: -1.5px;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.purple};
  }

  ul,
  li {
    list-style-type: disc;
    margin: 0 1.5rem;
  }

  .share {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey500};
  }

  .isPast {
    padding: 6rem 0;
    margin: 0 auto;
    text-align: center;

    .over {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.purple};
      font-size: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  .link {
    color: ${({ theme }) => theme.colors.purple};
    text-decoration: underline;
  }

  h2 {
    font-weight: 400;
    font-size: 1.125rem;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    align-items: start;

    ${({ theme }) => theme.breakpoints.tablet} {
      grid-template-columns: 1fr;
    }
  }

  .copy {
    display: grid;
    gap: 1.5rem;
    height: fit-content;

    .register-link {
      margin: 1.5rem 0;
    }
  }

  .details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: fit-content;
    gap: 1.5rem;

    .bold {
      font-weight: 600;
    }
  }

  .inner {
    max-width: 680px;
    margin: 0 auto;

    p {
      line-height: 1.6;
    }

    .heading {
      margin-top: 3rem;
    }

    * {
      margin-bottom: 1.5rem;
    }

    p {
      font-size: 1.125rem;
    }

    ${({ theme }) => theme.breakpoints.mobile} {
      p {
        font-size: 1rem;
      }
    }
  }

  .last {
    padding-bottom: 6rem;
  }
`

export default DrClaireAshtonJames
