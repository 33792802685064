import { ICalendar } from 'datebook'
import React from 'react'
import { Calendar } from 'react-feather'
import styled from 'styled-components'

type Props = {
  iCalendar: ICalendar
}

const ICalendarWidget: React.FC<Props> = ({ iCalendar }) => {
  return (
    <Button onClick={() => iCalendar.download()}>
      <Calendar size={32}/>
      Download iCalendar
    </Button>
  )
}

const Button = styled.div`
  display: grid;
  grid-auto-flow: column;
  background: #fff;
  gap: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  width: fit-content;
  border: 1px solid ${({theme}) => theme.colors.grey300};
  align-items: center;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background: #fafafa;
  }

  svg {
    width: 32px;
    max-width: 32px;
  }
`

export default ICalendarWidget
