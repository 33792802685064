import React, { memo } from 'react'
import { ICalendar, CalendarOptions, GoogleCalendar } from 'datebook'
import GoogleCalendarEvent from './GoogleCalendarEvent'
import ICalendarWidget from './ICalendar'
import styled from 'styled-components'

type Props = {
  title: string
  location: string
  description: string
  startDateTime: string
  endDateTime: string
  className?: string
}


const CalendarEvents: React.FC<Props> = ({ className, title, description, location, startDateTime, endDateTime }) => {
  const config: CalendarOptions = {
    title,
    location,
    description,
    start: new Date(startDateTime),
    end: new Date(endDateTime)
  }

  const icalendar = new ICalendar(config)
  const google = new GoogleCalendar(config)

  return (
    <Wrapper className={className}>
      <GoogleCalendarEvent url={google.render()}/>
      <ICalendarWidget iCalendar={icalendar}/>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  font-size: 0.875rem;
`

export default memo(CalendarEvents)
